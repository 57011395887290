import { useBreakpointToValue } from '@pretto/zen/reveal/lib/useBreakpointToValue'

import * as S from './ModalForBelgian.styles'

type ModalForBelgianProps = {
  onClose: () => void
  onLeave: () => void
}

export const ModalForBelgian = ({ onClose, onLeave, ...props }: ModalForBelgianProps) => {
  const buttonLabel = useBreakpointToValue<React.ReactNode>(<S.CrossPicto />, { tablet: 'Fermer' })

  return (
    <S.ModalOverlay {...props}>
      <S.ModalContainer>
        <S.SuptitleRow>
          <S.Suptitle>Vous êtes actuellement en Belgique</S.Suptitle>
          <S.CloseButton onClick={onClose}>{buttonLabel}</S.CloseButton>
        </S.SuptitleRow>

        <S.Title>Où souhaitez-vous acheter votre bien immobilier ?</S.Title>

        <S.Content>
          <p>
            Pretto accompagne les résidents vivant en Belgique et qui souhaitent <strong>investir en France</strong>.
          </p>
          <br />
          <p>
            Pour le reste, nous vous proposons une solution avec notre partenaire de confiance{' '}
            <S.Link onClick={onLeave}>Simulationpret.be</S.Link>.
          </p>
        </S.Content>

        <S.ImageWrapper>
          <S.Image path="v1706026632/reveal/static-asssets/illustrations/static/france-belgique.svg" />
        </S.ImageWrapper>

        <S.HighligthedButton onClick={onClose}>Je veux acheter en France</S.HighligthedButton>

        <S.DiscreetButton onClick={onLeave}>Je souhaite acheter en Belgique</S.DiscreetButton>
      </S.ModalContainer>
    </S.ModalOverlay>
  )
}
