import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import BaseImage from '@pretto/bricks/website/shared/components/Image'

import { PrimaryButtonFix } from '@pretto/zen/reveal/atoms/buttons/primaryButtonFix/PrimaryButtonFix'
import { addAlpha } from '@pretto/zen/reveal/lib/addAlpha'

import { TimesBold } from '@pretto/picto'

import styled from 'styled-components'

export const ModalOverlay = styled.div`
  ${grid()};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: ${({ theme }) => theme.colors.white};
  overflow: auto;

  @media screen and (min-width: ${breakpoints.tablet}) {
    background-color: ${({ theme }) => addAlpha(theme.colors.neutral1, 0.5)};
  }
`

export const ModalContainer = styled.div`
  ${column([1, 6])};
  padding: ${g(2)} ${g(2, 4)};
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.white};
  margin-top: auto;
  margin-bottom: auto;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    padding: ${g(6)} ${g(5)};
    border-radius: ${g(1)};
    justify-content: center;
    align-items: center;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([4, 8])};
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: ${g(6)} ${g(7)};
  }
`

export const SuptitleRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${g(1)};
  width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}) {
    justify-content: center;
  }
`

export const Suptitle = styled.div`
  ${({ theme }) => theme.typos.heading6};

  @media screen and (min-width: ${breakpoints.tablet}) {
    text-align: center;
  }
`

export const CloseButton = styled.button`
  cursor: pointer;
  margin-left: auto;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${({ theme }) => theme.typos.linkUnderline};
    position: absolute;
    top: ${g(4)};
    right: ${g(3)};
    margin-left: unset;
  }
`

export const CrossPicto = styled(TimesBold)`
  display: block;
  height: ${g(2)};
  width: ${g(2)};
`

export const Title = styled.div`
  ${({ theme }) => theme.typos.heading4}
  margin-bottom: ${g(4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    text-align: center;
  }
`

export const Content = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    text-align: center;
  }
`

export const Link = styled.button`
  ${({ theme }) => theme.typos.body4Underline};
  color: ${({ theme }) => theme.colors.primary1};
  cursor: pointer;
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 200px;
  padding: 0px ${g(2, -2)};
`

export const Image = styled(BaseImage).attrs({
  options: {
    crop: 'fill',
    width: 172,
  },
})`
  display: block;
  object-fit: contain;
`

export const HighligthedButton = styled(PrimaryButtonFix)``

export const DiscreetButton = styled.button`
  ${({ theme }) => theme.typos.body4Underline};
  margin-top: ${g(2)};
  cursor: pointer;
`
