import ThemeProvider from '@pretto/bricks/components/utility/ThemeProvider'

import { PictoProvider } from '@pretto/picto'

import React from 'react'

export const wrapRootElement = ({ element }) => (
  <PictoProvider>
    <ThemeProvider designSystem="reveal">{element}</ThemeProvider>
  </PictoProvider>
)
