import CookieBanner from '@pretto/bricks/shared/components/CookieBanner'

import { useModalForBelgian } from '@pretto/website/src/components/ModalForBelgian/useModalForBelgian'
import { useHasMounted } from '@pretto/website/utilities/useHasMounted'

import loadable from '@loadable/component'
import { loadPreferences } from '@segment/consent-manager'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

const ConsentManagerView = loadable(() => import(`@pretto/website/src/components/ConsentManager/ConsentManagerView`))

const ConsentManager = props => {
  const hasMounted = useHasMounted()
  const cookieBarPropsAlreadyDisplayed = Object.keys(loadPreferences()).length > 0

  const [isReady, setIsReady] = useState(cookieBarPropsAlreadyDisplayed)
  const [isHardClosed, setIsHardClosed] = useState(false)

  const [cookieBarProps, setCookieBarProps] = useState({
    onAcceptAllClick: () => setIsHardClosed(true),
    onParametersClick: () => setIsReady(true),
    onRefuseAllClick: () => setIsHardClosed(true),
    isVisible: true,
  })

  const { mustModalForBelgianBeDisplayed, modaleForBelgianComponent } = useModalForBelgian()

  const isCookieBannerDisplayed = !isHardClosed && !cookieBarPropsAlreadyDisplayed && cookieBarProps.isVisible

  useEffect(() => {
    if (isReady) return

    const onPageLoad = () => {
      setIsReady(true)

      window.removeEventListener('mousemove', onPageLoad)
      window.removeEventListener('touchstart', onPageLoad)
      window.removeEventListener('keydown', onPageLoad)
    }

    window.addEventListener('mousemove', onPageLoad, { once: true, passive: true })
    window.addEventListener('touchstart', onPageLoad, { once: true, passive: true })
    window.addEventListener('keydown', onPageLoad, { once: true, passive: true })

    return () => {
      window.removeEventListener('mousemove', onPageLoad)
      window.removeEventListener('touchstart', onPageLoad)
      window.removeEventListener('keydown', onPageLoad)
    }
  }, [])

  const getCookieBarProps = cookieProps => {
    setCookieBarProps(cookieProps)
  }

  if (!hasMounted) {
    return null
  }

  return (
    <>
      {isReady && <ConsentManagerView {...props} getCookieBarProps={getCookieBarProps} />}

      {isCookieBannerDisplayed && <CookieBanner {...cookieBarProps} />}

      {!isCookieBannerDisplayed && mustModalForBelgianBeDisplayed && modaleForBelgianComponent}
    </>
  )
}

ConsentManager.propTypes = {
  location: PropTypes.object.isRequired,
}

export default ConsentManager
