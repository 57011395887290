import abConfig from '@pretto/website/config/abtest'

import Cookies from 'js-cookie'
import qs from 'qs'

import ConsentManager from './src/components/ConsentManager'

const persistedUtmFlagsLastClicked = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']

// Persist utm query params in cookies, if not already present
const persistedUtmFlags = [...persistedUtmFlagsLastClicked, 'gclid', 'msclkid', 'utm_pretto_free']

const persistUtmQueryParams = location => {
  const params = qs.parse(location.search, { ignoreQueryPrefix: true })

  persistedUtmFlags.forEach(flag => {
    const value = params[flag]

    persistUtmFlagLastClicked(flag, value)

    if (value && !Cookies.get(flag)) {
      // 6 month expiration
      Cookies.set(flag, value, { domain: '.pretto.fr', expires: 183, path: '/' })
    }
  })
}

const persistUtmFlagLastClicked = (flag, value) => {
  if (value && persistedUtmFlagsLastClicked.includes(flag)) {
    Cookies.set(`${flag}_last_clicked`, value, { domain: '.pretto.fr', path: '/' })
  }
}

export { wrapRootElement } from './gatsby-shared'

export const wrapPageElement = ({ element, props }) => (
  <>
    {element}
    <ConsentManager {...props} />
  </>
)

const TRACK_PAGE_DELAY = 300

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation === null) {
    persistUtmQueryParams(location)
  }

  function trackSegmentPage() {
    window.setTimeout(() => {
      const traits = abConfig.reduce((previous, { id }) => {
        const name = `AB__${id}`
        const cookie = Cookies.get(name)

        if (!cookie) {
          return previous
        }

        return { ...previous, [name]: cookie }
      }, {})

      // eslint-disable-next-line no-unused-expressions
      window.analytics && window.analytics.page(null, null, traits)
    }, TRACK_PAGE_DELAY)
  }

  if (prevLocation && window.segmentSnippetLoaded === false) {
    window.segmentSnippetLoader(() => {
      trackSegmentPage()
    })
  } else {
    trackSegmentPage()
  }
}
