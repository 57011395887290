import { ModalForBelgian } from '@pretto/website/src/components/ModalForBelgian/component/ModalForBelgian'
import { trackAction } from '@pretto/website/src/utilities/tracking'

import { useEffect, useState } from 'react'

const MODAL_FOR_BELGIAN_KEY = 'isModalForBelgianRejected'
const BELGIUM_ISO_CODE = 'BE'
const PARTNER_URL =
  'https://www.simulationpret.be/pret-hypothecaire/demande/?utm_source=pretto&utm_medium=partner&utm_campaign=pretto'
const LOCATION_API = 'https://api.country.is/'

export const useModalForBelgian = () => {
  const [mustModalForBelgianBeDisplayed, setMustModalForBelgianBeDisplayed] = useState(false)

  useEffect(() => {
    const isModalForBelgianRejected = localStorage.getItem(MODAL_FOR_BELGIAN_KEY) === 'true'

    if (!isModalForBelgianRejected) {
      ;(async () => {
        await fetch(LOCATION_API)
          .then(res => {
            return res.json()
          })
          .then(data => {
            const isUserBelgian = data.country === BELGIUM_ISO_CODE
            setMustModalForBelgianBeDisplayed(isUserBelgian)
          })
      })()
    }
  }, [])

  const handleOnClose = () => {
    setMustModalForBelgianBeDisplayed(false)
    localStorage.setItem(MODAL_FOR_BELGIAN_KEY, 'true')
  }

  const handleOnLeave = () => {
    trackAction('LEFT_TO_BELGIAN_PARTNER')
    window.location.href = PARTNER_URL
  }

  const modaleForBelgianComponent = <ModalForBelgian onClose={handleOnClose} onLeave={handleOnLeave} />

  return { mustModalForBelgianBeDisplayed, modaleForBelgianComponent }
}
