/* globals analytics */
import Cookies from 'js-cookie'

import abConfig from '../../config/abtest.json'

export const trackAction = (name, payload = {}) => {
  const compound = abConfig.reduce(
    (previous, { id }) => {
      const name = `AB__${id}`
      const cookie = Cookies.get(name)

      if (!cookie) {
        return previous
      }

      return { ...previous, [name]: cookie }
    },
    { ...payload }
  )

  if (process.env.NODE_ENV === 'development' && !process.env.ENABLE_TRACKING) {
    // eslint-disable-next-line no-console
    console.log('Track Action', name, compound)
  } else analytics.track(name, compound)
}

export const useTracking = () => {
  const track = (name, properties = {}) => {
    trackAction(name, properties)
  }
  return track
}
